/* Modal Overlay */
.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Box */
.delete-modal {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 350px;
  max-width: 90%;
}

/* Title */
.delete-modal h3 {
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #222;
}

/* Modal Text */
.delete-modal p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

/* Buttons Container */
.modal-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
}

/* Delete Button */
.confirm-delete {
  background: #e63946;
  color: white;
  padding: 10px 18px;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
}

.confirm-delete:hover {
  background: #d62839;
  transform: scale(1.05);
}

.confirm-delete:active {
  transform: scale(0.98);
}

/* Cancel Button */
.cancel {
  background: #f1f1f1;
  color: #333;
  padding: 10px 18px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
}

.cancel:hover {
  background: #ddd;
  transform: scale(1.05);
}

.cancel:active {
  transform: scale(0.98);
}
