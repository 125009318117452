/* General Styles */
.dispatch-container {
    padding: 1.5rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .dispatch-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .dispatch-header h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
  }
  
  .add-btn {
    display: flex;
    align-items: center;
    background: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s;
  }
  
  .add-btn:hover {
    background: #0056b3;
  }
  
  .add-btn .icon {
    margin-right: 8px;
  }
  
  /* Dispatch Grid */
  .dispatch-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    gap: 20px;
  }
  
  /* Dispatch Card */
  .dispatch-card {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .dispatch-card:hover {
    transform: scale(1.02);
  }
  
  .dispatch-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dispatch-card-header h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .email {
    font-size: 0.9rem;
    color: #777;
  }
  
  /* Menu Button */
  .menu-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background 0.3s;
  }
  
  .menu-btn:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  /* Menu Dropdown */
  .menu-dropdown {
    position: absolute;
    right: 0;
    top: 40px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow: hidden;
    z-index: 100;
  }
  
  .menu-dropdown button {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background 0.3s;
  }
  
  .menu-dropdown button:hover {
    background: #f5f5f5;
  }
  
  .menu-dropdown .delete {
    color: red;
  }
  
  /* Dispatch Details */
  .dispatch-details p {
    margin: 8px 0;
    font-size: 0.95rem;
    color: #555;
  }
  
  .dispatch-details strong {
    color: #333;
  }
  
  .dispatch-details a {
    color: #007bff;
    text-decoration: none;
  }
  
  .dispatch-details a:hover {
    text-decoration: underline;
  }
  
  /* No Data */
  .no-data {
    text-align: center;
    font-size: 1rem;
    color: #777;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
  
  .pagination button {
    background: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .pagination button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    background: #0056b3;
  }
  