/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 95%;
  max-width: 480px; /* Keeps the modal consistent */
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.75rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.4rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  font-size: 15px;
}

/* Form Fields */
.form-label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

/* Input Fields - Uniform Width */
.modal-body input,
.modal select,
.custom-textarea {
  width: 100%;
  font-size: 15px;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease;
  box-sizing: border-box; /* Ensures consistent width */
}

/* Focus State */
.modal-body input:focus,
.modal select:focus,
.custom-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Textarea */
.custom-textarea {
  min-height: 100px;
  resize: vertical;
}

/* File Input Styling */
.modal-body input[type="file"] {
  border: none;
  padding: 5px;
  background-color: transparent;
}

/* Select Dropdown */
.modal select {
  cursor: pointer;
}

.modal select:hover {
  border-color: #007bff;
  background-color: #f8f9fa;
}

/* Multi-Select */
.modal select[multiple] {
  height: auto;
  max-height: 120px;
  overflow-y: auto;
}

.modal select option:checked {
  background-color: #007bff;
  color: #fff;
}

/* Special Instructions & Users Box */
.modal-body textarea,
.modal-body .user-list {
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  background: #f9f9f9;
  color: #333;
  font-size: 15px;
  box-sizing: border-box;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.75rem;
}

/* Submit Button */
.submit-btn {
  background: #007bff;
  color: white;
  padding: 0.5rem 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  transition: background 0.3s;
}

.submit-btn:hover {
  background: #0056b3;
}
